<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
            v-model="inscricaoTEF.instituicaoEnsino"
            :item-text="
            item => (item.nome + (item.sigla ? ' - ' + item.sigla : ''))
          "
            :items="instituicoesEnsino"
            :rules="[campoObrigatorioRule(inscricaoTEF.instituicaoEnsino.oid)]"
            dense
            label="Instituição de Ensino de graduação que está cursando *"
            outlined
            return-object
            validate-on-blur
        />
      </v-col>
      <v-col cols="6">
        <v-select
            v-if="processo.possuiUniversidadeOrigem"
            v-model="inscricaoTEF.anoIngressoInstituicaoOrigem"
            :items="processo.listaAnoIngressoUniversidadeOrigem"
            :rules="[campoObrigatorioRule]"
            dense
            label="Ano de ingresso na universidade de origem *"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-else
            v-model="inscricaoTEF.anoIngressoInstituicaoOrigem"
            v-mask="'####'"
            :rules="[campoObrigatorioRule, tamanhoRule(4)]"
            dense
            label="Ano de ingresso na universidade de origem *"
            name="inscricaoUniversidadeDeOrigem"
            outlined
            validate-on-blur
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import instituicaoEnsinoService from "@/services/instituicaoEnsino.service";

export default {
  name: "InscricaoFormTEF",

  components: {
    EscolhaOpcaoSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  data() {
    return {
      instituicoesEnsino: [],
      inscricaoTEF: {
        inscricao: null,
        anoIngressoInstituicaoOrigem: null,
        instituicaoEnsino: {}
      }
    };
  },

  async created() {
    await instituicaoEnsinoService
        .recuperarInstituicoesEnsino()
        .then(response => {
          this.instituicoesEnsino = response.data;
        })
        .catch(() => {
        });
  },

  watch: {
    inscricaoTEF: {
      handler(){
        this.inscricaoTEF.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoTEF);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
};
</script>
